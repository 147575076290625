import { createStore } from "redux";
import { setStorage } from "src/utils/utils";
import themeContext from "src/context/ThemeContext";

const initialState = {
  sidebarShow: "responsive",
  trasactionData: [],
  gameCreditData: [],
  reloadWithDrawReq: false,
  reloadDeposit: false,
  depositNotiCounter: 0,
  newMemberNotiCounter: 0,
  withdrawTransaction: 0,
  systemImage: {},
  reloadSystemImage: true,
  themeMode: true,
  themeStyle: themeContext.dark,
  systemBalance: 0,
  linkImage: {},
  descriptionImage: {},
};

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "ADD_TRANSACTION":
      let { data } = rest;
      let storeTrx = [...state.trasactionData];
      storeTrx.push(data);
      return { ...state, trasactionData: storeTrx };
    case "REMOVE_TRANSACTION":
      let { _id } = rest;
      let nextTrx = [...state.trasactionData];
      nextTrx = nextTrx.filter((obj) => {
        return obj._id !== _id;
      });
      return { ...state, trasactionData: nextTrx };
    case "ADD_GAME_CREDIT_DATA":
      let game_data = rest.data;
      let storeGameCredit = [...state.gameCreditData];
      storeGameCredit.push(game_data);
      return { ...state, gameCreditData: storeGameCredit };
    case "REMOVE_GAME_CREDIT_DATA":
      let { game_id } = rest;
      let nextGameData = [...state.gameCreditData];
      nextGameData = nextGameData.filter((obj) => {
        return obj._id !== game_id;
      });
      return { ...state, gameCreditData: nextGameData };
    case "TRANSACTION_WITHDRAW":
      let { status } = rest;
      return { ...state, reloadWithDrawReq: status };
    case "DEPOSIT_INCOME":
      return { ...state, reloadDeposit: rest.status };
    case "SET_COUNTER_NOTIFICATION":
      return { ...state, ...rest };
    case "SET_SYSTEM_IMAGE":
      return { ...state, ...rest };
    case "TRIGGER_IMAGE_RELOAD":
      return { ...state, ...rest };
    case "SET_THEME_MODE":
      let nextStyle = rest.themeMode ? themeContext.dark : themeContext.light;
      setStorage("theme_mode", rest.themeMode);
      return {
        ...state,
        ...rest,
        themeStyle: nextStyle,
        themeMode: rest.themeMode,
      };
    case "SET_SYSTEM_BALANCE":
      return { ...state, ...rest };
    case "SET_LINK_IMAGE":
      return { ...state, ...rest };
    case "SET_DESCRIPTION_IMAGE":
      return { ...state, ...rest };
    default:
      return state;
  }
};

const store = createStore(changeState);
export default store;
