import $ from "jquery";
import { StaffService } from "../services/UserServices";

const timeTwoDigit = (num) => {
  return (num < 10 ? "0" : "") + num;
};

export const compare = (a, b) => {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
};
export const userRefresh = () => {
  const obj = localStorage.getItem("userId");
  if (obj) {
    StaffService.getUser(obj)
      .then(({ data }) => {
        setStorage(
          "permissions_ignore",
          JSON.stringify(data.permission.permissions)
        );
      })
      .catch((err) => {});
  }
  return obj;
};

export const formatDateTime = (time, type) => {
  let d = new Date(time);
  if (isNaN(d?.getTime())) {
    return "Invalid";
  }
  let h = timeTwoDigit(d.getHours());
  let m = timeTwoDigit(d.getMinutes());
  let s = timeTwoDigit(d.getSeconds());

  if (type === "onlyTime") {
    return `${h}:${m}:${s}`;
  } else if (type) {
    return `${d.toLocaleDateString("th-TH")}`;
  }
  return `${d.toLocaleDateString("th-TH")} - ${h}:${m}:${s}`;
};

// ====== LOCAL STORAGE ======
export const getStorage = (key) => {
  return localStorage.getItem(key);
};

export const setStorage = (key, value) => {
  return localStorage.setItem(key, value);
};
// ===========================

export const getPermissions = (type = "page") => {
  let block = [];
  let pIgnore = JSON.parse(localStorage.getItem("permissions_ignore"));
  if (!pIgnore) {
    pIgnore = [];
  }
  for (const permis of pIgnore) {
    if (permis.type === type || type === "all") {
      block.push(permis.path);
    }
  }
  return block;
};

export const checkPermissions = (key) => {
  let pIgnore = JSON.parse(localStorage.getItem("permissions_ignore"));
  if (!pIgnore) {
    pIgnore = [];
  }

  return pIgnore.findIndex((p) => p.key === key) < 0;
};

export const Validate = {
  Form: (store) => {
    let verifyList = [];
    $.each(store, (idx, record) => {
      let type = record.nodeName;
      let isSelect = $(record).hasClass("ant-select-dark");
      let verify = true;

      // console.log("DOM ==> ", record);

      if (type === "DIV" || type === "SPAN") {
        if (isSelect) {
          if ($(record).find(".ant-select-selection-placeholder")[0]) {
            verify = false;
          }
        } else {
          let isTextArea = $(record).hasClass("textarea-dark");
          if (isTextArea) {
            if ($(record).find("textarea")[0].innerHTML.trim() === "") {
              record = $(record).find("textarea")[0];
              verify = false;
            }
          } else if (
            $(record).find("input").val().trim() === "" ||
            $(record).find("input").val() === null
          ) {
            record = $(record).find("input");
            verify = false;
          }
        }
      } else {
        if ($(record).val() === null || $(record).val().trim() === "") {
          verify = false;
        }
      }

      verify
        ? $(record).removeClass("input-validate-error")
        : $(record).addClass("input-validate-error");
      verifyList.push(verify);
    });
    // console.log("LIST OF DATA ==> ", verifyList);
    return !verifyList.includes(false);
  },
  Input: (target) => {
    $(target).val() === null || $(target).val().trim() === ""
      ? $(target).addClass("input-validate-error")
      : $(target).removeClass("input-validate-error");
  },
  ResetClass: (store) => {
    $.each(store, (idx, record) => {
      if ($(record).hasClass("textarea-dark")) {
        $(record).find("textarea").removeClass("input-validate-error");
      } else if (record.nodeName === "SPAN") {
        $(record).find("input").removeClass("input-validate-error");
      } else {
        $(record).removeClass("input-validate-error");
      }
    });
  },
};

export const getDefaultValue = (value) => {
  let val = parseFloat(value).toFixed(2);
  let temp = val.split(".");
  let itg = parseInt(temp[0]).toLocaleString();
  let digit = temp[1];

  return value ? `${itg}.${digit}` : "0.00";
};

export const antDummyRequest = ({ file, onSuccess }) => {
  return setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export const getThemeModeValue = () => {
  return getStorage("theme_mode") ? getStorage("theme_mode") === "true" : true;
};

export const getStatusTag = (status) => {
  switch (status) {
    case true:
      return { color: "green", aka: "txt_active" };
    case false:
      return { color: "red", aka: "txt_inactive" };
    default:
      return {};
  }
};

export const getNameCategory = (category, t) => {
  switch (category) {
    case "ALL":
      return `${t("txt_all_games")}`;
    case "CASINO":
      return `${t("txt_casino_games")}`;
    case "SLOT":
      return `${t("txt_slot_games")}`;
    case "FISH":
      return `${t("txt_fish_games")}`;
    case "SPORT":
      return `${t("txt_sport_games")}`;
    case "LOTTERY":
      return `${t("txt_lottery_games")}`;
    default:
      return {};
  }
};

export const getTag = (status, t) => {
  switch (status) {
    case true:
      return {
        // icon: <CheckCircleOutlined />,
        name: `${t("txt_open")}`,
        color: "#28a745",
      };
    case false:
      return {
        // icon: <ExclamationCircleOutlined />,
        name: `${t("txt_inactive")}`,
        color: "#dc3545",
      };

    default:
      return { name: "-", color: "" };
  }
};

export const API_PREFIX = window.env.REACT_APP_DEV_ENDPOINT;
