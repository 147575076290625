import axios from "axios";
import { getStorage } from "../utils/utils";

const ENDPOINT = {
  STAFF: {
    CREATE: `${window.env.REACT_APP_DEV_ENDPOINT}/user`,
    GET: (userId) => `${window.env.REACT_APP_DEV_ENDPOINT}/user/${userId}`,
    UPDATE: (userId) => `${window.env.REACT_APP_DEV_ENDPOINT}/user/${userId}`,
    DELETE: (userId) => `${window.env.REACT_APP_DEV_ENDPOINT}/user/${userId}`,
    GET_ALL: (config) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/user?page=${config.page}&limit=${config.pageLimit}&query=${config.query}`,
    SIGN_IN: `${window.env.REACT_APP_DEV_ENDPOINT}/auth/signin`,
    CHANGE_PASSWORD: `${window.env.REACT_APP_DEV_ENDPOINT}/user/change-password`,
    SET_STATUS: (userId) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/user/set-status/${userId}`,
    SET_2FA: `${window.env.REACT_APP_DEV_ENDPOINT}/user/enable-2fa`,
  },

  MEMBER: {
    CREATE: `${window.env.REACT_APP_DEV_ENDPOINT}/member`,
    UPDATE: (memberId) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/member/${memberId}`,
    DELETE: (memberId) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/member/${memberId}`,
    GET_ALL: (config) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/member?page=${config.page}&limit=${config.pageLimit}&query=${config.query}`,
    GET_BY_USER_AGENT: (userAgent) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/member/user/agent/${userAgent}`,
    SET_CREDIT: `${window.env.REACT_APP_DEV_ENDPOINT}/transaction`,
    SET_EXP: `${window.env.REACT_APP_DEV_ENDPOINT}/ranking/exp`,
    SET_POINT: `${window.env.REACT_APP_DEV_ENDPOINT}/point`,
    SET_TURNOVER: `${window.env.REACT_APP_DEV_ENDPOINT}/member/set-turn`,
    SET_FIXED_DEPOSIT: `${window.env.REACT_APP_DEV_ENDPOINT}/member/add-fixed-deposit`,
    GET_INACTIVE: (config) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/member/transaction/inactive?page=${config.page}&limit=${config.pageLimit}&query=${config.query}`,
    ADD_PROMOTION_BY_MEMBER_ID: `${window.env.REACT_APP_DEV_ENDPOINT}/member/add-promotion-one`,
    DEL_PROMOTION_BY_MEMBER_ID: `${window.env.REACT_APP_DEV_ENDPOINT}/member/delete-promotion-one`,
    CHANGE_PASSWORD: `${window.env.REACT_APP_DEV_ENDPOINT}/member/change-password`,
    GET_GAME_HISTORY: `${window.env.REACT_APP_DEV_ENDPOINT}/game-history`,

    GET_MANUAL_CREDIT_HISTORY: (memberId) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/transaction/credit-manual/${memberId}`,
    GET_MANUAL_EPX_HISTORY: (memberId) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/ranking/exp/${memberId}`,
    GET_MANUAL_POINT_HISTORY: (memberId) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/point/manual/${memberId}`,
    GET_MANUAL_TURN_HISTORY: (memberId) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/member/turn-log/${memberId}`,
    GET_MANUAL_FIXED_DEPOSIT_HISTORY: (memberId) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/member/fixed-deposit/${memberId}`,
    CHANGE_ENABLE_POINT: (memberId) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/member/set/enable-point/${memberId}`,
    CLEAR_FISH_CREDITS: () =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/member/clear-fish-credit`,
    GET_GAME_HISTORY_BET: `${window.env.REACT_APP_DEV_ENDPOINT}/game-history/detail-bet`,
    CHANGE_USER_AGENT: (memberId) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/member/re-user/${memberId}`,
    BANNED_MEMBER: (memberId) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/member/toggle-suspend/${memberId}`,
    BLACKLIST_MEMBER: (config) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/blacklist?page=${config.page}&limit=${config.pageLimit}&query=${config.query}`,
    ADD_BLACKLIST_MEMBER: `${window.env.REACT_APP_DEV_ENDPOINT}/blacklist`,
    UNBANNED_BLACKLIST_MEMBER: (_id) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/blacklist/${_id}`,
    ADD_PARTNER: `${window.env.REACT_APP_DEV_ENDPOINT}/member/partner/create`,
    DEL_PARTNER: `${window.env.REACT_APP_DEV_ENDPOINT}/member/partner/delete`,
    EDIT_PARTNER: `${window.env.REACT_APP_DEV_ENDPOINT}/member/partner/update`,
    PARTNER_LIST: `${window.env.REACT_APP_DEV_ENDPOINT}/member/partner/list`,
  },
};

export const StaffService = {
  signIn: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.STAFF.SIGN_IN,
      data,
    });
  },

  getUser: (userId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.STAFF.GET(userId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  changePassword: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.STAFF.CHANGE_PASSWORD,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  signUp: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.STAFF.CREATE,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  updateUser: (data) => {
    return axios({
      method: "PATCH",
      url: ENDPOINT.STAFF.UPDATE(data._id),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  deleteUser: (userId) => {
    return axios({
      method: "DELETE",
      url: ENDPOINT.STAFF.DELETE(userId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  fetchUser: (config) => {
    return axios({
      method: "GET",
      url: ENDPOINT.STAFF.GET_ALL(config),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  toggleUserStatus: (userId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.STAFF.SET_STATUS(userId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  set2FaByUserId: (userId) => {
    return axios({
      method: "POST",
      url: ENDPOINT.STAFF.SET_2FA,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data: { user_id: userId },
    });
  },
};

// ==== END STAFF SERVICE ===== //

// ==== MEMBER SERVICE ===== //
export const MemberService = {
  memberSignUp: (data) => {
    return axios({
      method: "post",
      url: ENDPOINT.MEMBER.CREATE,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  changePassword: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.CHANGE_PASSWORD,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  fetchMember: (config) => {
    return axios({
      method: "GET",
      url: ENDPOINT.MEMBER.GET_ALL(config),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  getMemberByUserAgent: (userAgent) => {
    return axios({
      method: "GET",
      url: ENDPOINT.MEMBER.GET_BY_USER_AGENT(userAgent),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  deleteMember: (memberId) => {
    return axios({
      method: "DELETE",
      url: ENDPOINT.MEMBER.DELETE(memberId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  updateMember: (data, memberID) => {
    return axios({
      method: "PATCH",
      url: ENDPOINT.MEMBER.UPDATE(memberID),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  setCreditByMemberId: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.SET_CREDIT,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  setExpByMemberId: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.SET_EXP,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  setPointByMemberId: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.SET_POINT,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  setTurnover: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.SET_TURNOVER,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  setFixedDeposit: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.SET_FIXED_DEPOSIT,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  getInactive: (config) => {
    return axios({
      method: "GET",
      url: ENDPOINT.MEMBER.GET_INACTIVE(config),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  addPromotionByMemberId: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.ADD_PROMOTION_BY_MEMBER_ID,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  delPromotionByMemberId: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.DEL_PROMOTION_BY_MEMBER_ID,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  getGameHistory: (config) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.GET_GAME_HISTORY,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data: config,
    });
  },

  getManualCreditHistory: (memberId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.MEMBER.GET_MANUAL_CREDIT_HISTORY(memberId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },
  getManualExpHistory: (memberId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.MEMBER.GET_MANUAL_EPX_HISTORY(memberId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  getManualPointHistory: (memberId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.MEMBER.GET_MANUAL_POINT_HISTORY(memberId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },
  getManualTurnHistory: (memberId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.MEMBER.GET_MANUAL_TURN_HISTORY(memberId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },
  getManualFixedDepositHistory: (memberId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.MEMBER.GET_MANUAL_FIXED_DEPOSIT_HISTORY(memberId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  changeEnablePoint: (memberId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.MEMBER.CHANGE_ENABLE_POINT(memberId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },
  clearFishCredits: (memberId) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.CLEAR_FISH_CREDITS(memberId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data: { member_id: memberId },
    });
  },
  getGameHistoryBet: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.GET_GAME_HISTORY_BET,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data: data,
    });
  },
  changeUserAgent: (memberId) => {
    return axios({
      method: "GET",
      url: ENDPOINT.MEMBER.CHANGE_USER_AGENT(memberId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },
  bannedMember: (data, memberid) => {
    return axios({
      method: "PATCH",
      url: ENDPOINT.MEMBER.BANNED_MEMBER(memberid),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data: { status: data },
    });
  },
  blacklistMember: (config) => {
    return axios({
      method: "GET",
      url: ENDPOINT.MEMBER.BLACKLIST_MEMBER(config),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },
  addBlacklist: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.ADD_BLACKLIST_MEMBER,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data: data,
    });
  },
  unbannedBlacklist: (memberId, data) => {
    return axios({
      method: "DELETE",
      url: ENDPOINT.MEMBER.UNBANNED_BLACKLIST_MEMBER(memberId),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },
  addPartner: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.ADD_PARTNER,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },
  partnerDel: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.DEL_PARTNER,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },
  partnerEdit: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.EDIT_PARTNER,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },
  partnerList: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.MEMBER.PARTNER_LIST,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },
};
