import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
// import LanguageDetector from 'i18next-browser-languagedetector'; //OPTIONAL
import th from "./locales/th/translation.json";
import lo from "./locales/lo/translation.json";
import en from "./locales/en/translation.json";
import kr from "./locales/kr/translation.json";
import ph from "./locales/ph/translation.json";
import { getStorage } from "./utils/utils";

i18n
  .use(Backend)
  // .use(LanguageDetector) // OPTIONAL
  .use(initReactI18next)
  .init({
    resources: {
      th: {
        translation: th,
      },
      lo: {
        translation: lo,
      },
      en: {
        translation: en,
      },
      kr: {
        translation: kr,
      },
      ph: {
        translation: ph,
      },
    },
    lng: getStorage("lang"),
    //  Set default language
    // lng: "th",
    //  language to use if translations in user language are not available
    fallbackLng: "th",
    debug: false,
    //  string or array of namespaces to load
    ns: "translation",
    //  default namespace used if not passed to 'translation function'
    defaultNS: "translation",

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
