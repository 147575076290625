import axios from "axios";
import { getStorage } from "../utils/utils";

let httpCongig = "https://s3.icasclub.com";

const ENDPOINT = {
  GAME: {
    WHEEL_CONFIG: `${window.env.REACT_APP_DEV_ENDPOINT}/reward`,
    FIXED_DEPOSIT_CONFIG: `${window.env.REACT_APP_DEV_ENDPOINT}/fixed-deposit`,
  },

  SYSTEM: {
    GET_CONFIG: `${window.env.REACT_APP_DEV_ENDPOINT}/system-config/find-config`,
    RESET_FIXED_DEPOSIT: `${window.env.REACT_APP_DEV_ENDPOINT}/fixed-deposit/reset`,
    UPDATE_CONFIG: `${window.env.REACT_APP_DEV_ENDPOINT}/system-config/update`,
    GET_IMAGE_COMFIG: `${httpCongig}/AGENT/${window.env.REACT_APP_NAME.toLowerCase()}-sys-image-config.json`,
    UPDATE_IMAGE: `${window.env.REACT_APP_DEV_ENDPOINT}/system-config/update-image`,
    GET_CAROUSEL: `${httpCongig}/AGENT/${window.env.REACT_APP_NAME.toLowerCase()}-media.json`,
    ADD_IMAGE_CAROUSEL: (type) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/media/${type}`,
    DELETE_IMAGE_CAROUSEL: (_id) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/media/${_id}`,
    WARNING_EXIT: `${window.env.REACT_APP_DEV_ENDPOINT}/system-config/warning/exit`,
    UPDATE_IMAGE_LINK: `${window.env.REACT_APP_DEV_ENDPOINT}/system-config/update-image-link`,
    // CONFIG_JSON: `${httpCongig}/AGENT/${window.env.REACT_APP_NAME.toLowerCase()}-media.json`,
  },

  RECYCLE: {
    GET_DELETED: (config) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/${config.type}/recycle/get?page=${config.page}&limit=${config.limit}`,
    GET_STATEMENT_DELETED: `${window.env.REACT_APP_DEV_ENDPOINT}/statement/recycle/get`,
    RECOVERY: (type, _id) =>
      `${window.env.REACT_APP_DEV_ENDPOINT}/${type}/recovery/${_id}`,
  },
};

const SystemService = {
  warningExit: () => {
    return axios({
      method: "GET",
      url: ENDPOINT.SYSTEM.WARNING_EXIT,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  resetFixedDeposit: () => {
    return axios({
      method: "GET",
      url: ENDPOINT.SYSTEM.RESET_FIXED_DEPOSIT,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },
  // ==== SYSTEM CONFIG ==== //
  getConfigById: (configRequest) => {
    return axios({
      method: "POST",
      url: ENDPOINT.SYSTEM.GET_CONFIG,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data: configRequest,
    });
  },

  updateConfig: (updatedList) => {
    return axios({
      method: "POST",
      url: ENDPOINT.SYSTEM.UPDATE_CONFIG,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data: updatedList,
    });
  },

  // ==== SYSTEM IMAGE CONFIG ==== //
  getImageConfigById: () => {
    return axios({
      method: "GET",
      url: ENDPOINT.SYSTEM.GET_IMAGE_COMFIG,
    });
  },

  updateImage: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.SYSTEM.UPDATE_IMAGE,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  // ===  RECYCLE ==== //
  getUserDeleted: (config) => {
    return axios({
      method: "GET",
      url: ENDPOINT.RECYCLE.GET_DELETED(config),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  getStatementDeleted: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.RECYCLE.GET_STATEMENT_DELETED,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  recoveryData: (type, _id) => {
    return axios({
      method: "GET",
      url: ENDPOINT.RECYCLE.RECOVERY(type, _id),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  getWheelConfig: () => {
    return axios({
      method: "GET",
      url: ENDPOINT.GAME.WHEEL_CONFIG,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  submitWheelConfig: (wheelData, deletedStore) => {
    return axios({
      method: "POST",
      url: ENDPOINT.GAME.WHEEL_CONFIG,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data: { wheel: wheelData, wheel_delete: deletedStore },
    });
  },

  getFixedDepositConfig: () => {
    return axios({
      method: "GET",
      url: ENDPOINT.GAME.FIXED_DEPOSIT_CONFIG,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  submitFixedDeposit: (data, deletedStore) => {
    return axios({
      method: "POST",
      url: ENDPOINT.GAME.FIXED_DEPOSIT_CONFIG,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data: { fixed_deposit: data, fixed_deposit_delete: deletedStore },
    });
  },

  // === CAROUSEL === //
  getCarouselList: () => {
    return axios({
      method: "GET",
      url: ENDPOINT.SYSTEM.GET_CAROUSEL,
      // headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  addImageCarousel: (data, type) => {
    return axios({
      method: "POST",
      url: ENDPOINT.SYSTEM.ADD_IMAGE_CAROUSEL(type),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  deleteImageCarousel: (_id) => {
    return axios({
      method: "DELETE",
      url: ENDPOINT.SYSTEM.DELETE_IMAGE_CAROUSEL(_id),
      headers: { Authorization: `Bearer ${getStorage("token")}` },
    });
  },

  updateImageLink: (data) => {
    return axios({
      method: "POST",
      url: ENDPOINT.SYSTEM.UPDATE_IMAGE_LINK,
      headers: { Authorization: `Bearer ${getStorage("token")}` },
      data,
    });
  },

  // configjson: () => {
  //   return axios({
  //     method: "GET",
  //     url: ENDPOINT.SYSTEM.CONFIG_JSON,
  //   });
  // },
};

export default SystemService;
