import React, { createContext, useReducer } from "react";

const lottoState = {
  listNumbers: [],
  listLeverage: [],
  totalBill: 0,
};

const lottoContext = createContext(lottoState);

const LottoContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(lottoReducer, lottoState);
  const setListNumbers = (payload) => {
    dispatch({ type: "SET_LIST_NUMBER", payload });
  };
  const setListLeverage = (payload) => {
    dispatch({ type: "SET_LIST_LEVERAGE", payload });
  };
  const addListNumbers = (payload) => {
    dispatch({ type: "ADD_LIST_NUMBER", payload });
  };
  const deleteListNumber = (payload) => {
    dispatch({ type: "DELETE_LIST_NUMBER", payload });
    console.log("DELETE_LIST_NUMBER payload :", payload);
  };

  const func = {
    setListNumbers,
    setListLeverage,
    addListNumbers,
    deleteListNumber,
    ...state,
  };

  return <lottoContext.Provider value={func}>{children}</lottoContext.Provider>;
};

export { lottoContext, LottoContextProvider };

const lottoReducer = (state, action) => {
  switch (action.type) {
    case "SET_LIST_NUMBER":
      return {
        ...state,
        listNumbers: [...action.payload],
      };
    case "SET_LIST_LEVERAGE":
      return {
        ...state,
        listLeverage: [...action.payload],
      };
    // case "DELETE_LIST_NUMBER":
    //   console.log(state.listNumbers);
    //   let delDataIndex = state.listNumbers.findIndex(
    //     (item) =>
    //       item.huay_select === action.payload.huay_select &&
    //       item.number === action.payload.number
    //   );
    //   state.listNumbers.splice(delDataIndex, 1);

    //   return {
    //     ...state,
    //     listNumbers: [...state.listNumbers],
    //   };
    default:
      return state;
  }
};
