import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loading from "src/components/Loading";
import SystemService from "./services/SystemServices";
import "./scss/style.scss";
import { CONFIG_RULES } from "src/context/constant";
import "./i18n";

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const UILogin = React.lazy(() => import("./views/pages/Auth/UILogin"));
const Page404 = React.lazy(() => import("./views/pages/404/Page404"));

const App = () => {
  const dispatch = useDispatch();
  const reloadSystemImage = useSelector((state) => state.reloadSystemImage);

  useEffect(() => {
    const fetchConfig = () => {
      SystemService.getImageConfigById([
        CONFIG_RULES.BACKGROUND_IMAGE,
        CONFIG_RULES.AVATAR_IMAGE,
        CONFIG_RULES.FIXED_DEPOSIT_IMAGE,
        CONFIG_RULES.ICON_IMAGE,
        CONFIG_RULES.BANNER_IMAGE,
        CONFIG_RULES.MEMBER_LOGIN_BACKGROUND_IMAGE,
        CONFIG_RULES.MEMBER_LOBBY_BACKGROUND_IMAGE,
        CONFIG_RULES.MEMBER_MODAL_LOGGED_IN,
        CONFIG_RULES.MEMBER_MODAL_LOGGED_IN_LINK,
        CONFIG_RULES.MEMBER_LINE_GROUP_IMAGE_1,
        CONFIG_RULES.MEMBER_LINE_GROUP_IMAGE_2,
        CONFIG_RULES.MEMBER_LINE_GROUP_IMAGE_3,
        CONFIG_RULES.MEMBER_LINE_GROUP_IMAGE_4,
        CONFIG_RULES.MEMBER_LINE_GROUP_IMAGE_5,
        CONFIG_RULES.MEMBER_SOCIAL,
      ]).then(({ data }) => {
        if (Array.isArray(data?.images)) {
          let images = {};
          for (const img of data?.images) {
            images[img.id] = img;
          }

          dispatch({
            type: "SET_SYSTEM_IMAGE",
            systemImage: { ...images },
          });
        } else {
          dispatch({
            type: "SET_SYSTEM_IMAGE",
            systemImage: { ...data?.images },
          });
        }

        dispatch({ type: "TRIGGER_IMAGE_RELOAD", reloadSystemImage: false });
      });
    };

    SystemService.getConfigById([
      CONFIG_RULES.MEMBER_MODAL_LOGGED_IN_LINK,
      CONFIG_RULES.MEMBER_LINE_GROUP_LINK_1,
      CONFIG_RULES.MEMBER_LINE_GROUP_LINK_2,
      CONFIG_RULES.MEMBER_LINE_GROUP_LINK_3,
      CONFIG_RULES.MEMBER_LINE_GROUP_LINK_4,
      CONFIG_RULES.MEMBER_LINE_GROUP_LINK_5,
    ]).then(({ data }) => {
      dispatch({ type: "SET_LINK_IMAGE", linkImage: data });
    });

    if (reloadSystemImage) {
      fetchConfig();
    }
  }, [reloadSystemImage]);

  return (
    <BrowserRouter>
      <React.Suspense fallback={Loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Login"
            render={(props) => <UILogin {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            path="/"
            name="Home"
            render={(props) => <TheLayout {...props} />}
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
