const themeContext = {
  light: {
    card: {
      cardCustom: "card-theme-background-white",
      summaryCard: { backgroundColor: "#FFF" },
    },
    pagination: { paginationCustom: "pagination-white" },
    input: { inputCustom: "ant-input-light", timePicker: "ant-picker-light" },
    button: { backgroundColor: "white", color: "black" },
    header: { backgroundColor: "#ebedef" },
    borderbottom: { borderBottom: "1px solid #E0E0E0" },
    sidebar: { backgroundColor: "#252f3e", color: "black" },
    fontColor: { color: "black" },
    backgroundColor: { color: "white" },
    footer: { backgroundColor: "#ebedef" },
    sidebarbrand: { backgroundColor: "#1B2330", color: "black" },
    content: { backgroundColor: "#F5F6F8" },
    table: { tableCustom: "table-theme-background-white" },
    matcard: { matCardCustom: "mat-card-custom-white" },
    sortgamecard: { matCardCustom: "sort_card_custom_white" },
    searchBox: "light-searchbar",
    searchGroup: "ant-inputgroup-light",
    datePicker: "datepicker-custom-light",
    selectCustom: "ant-select-white",
    muiCard: "mui-card-custom-white",
    textFieldmui: "mui-textfield-white",
    muiTableContainer: "mui-table-container-white",
    muiTableContainerTypeBet: "mui-table-container-white-typebet",
    muiCardTwo: "mui-card-two-custom-white",
    optionAnt: "ant-option-custom-white",
  },
  dark: {
    card: {
      cardCustom: "card-theme-background-black",
      summaryCard: { backgroundColor: "#1C1C1E" },
    },
    pagination: { paginationCustom: "pagination-dark" },
    input: { inputCustom: "ant-input-dark", timePicker: "ant-picker-dark" },
    button: { backgroundColor: "#000000", color: "white" },
    header: { backgroundColor: "#1C1C1E" },
    borderbottom: { borderBottom: "1px solid #636363" },
    sidebar: { backgroundColor: "#1C1C1E" },
    sidebarbrand: { backgroundColor: "#1C1C1E" },
    fontColor: { color: "white" },
    backgroundColor: { color: "#1C1C1E" },
    footer: { backgroundColor: "#1C1C1E" },
    content: { backgroundColor: "#000000" },
    toggle: { filterClass: "filtertoggle" },
    table: { tableCustom: "table-theme-background-dark" },
    matcard: { matCardCustom: "mat-card-custom-dark" },
    sortgamecard: { matCardCustom: "sort_card_custom_dark" },
    searchBox: "dark-searchbar",
    searchGroup: "ant-inputgroup-dark",
    datePicker: "datepicker-custom-dark",
    selectCustom: "ant-select-dark",
    muiCard: "mui-card-custom-dark",
    textFieldmui: "mui-textfield-dark",
    muiTableContainer: "mui-table-container-dark",
    muiTableContainerTypeBet: "mui-table-container-dark-typebet",
    muiCardTwo: "mui-card-two-custom-dark",
    optionAnt: "ant-option-custom-dark",
  },
};

export default themeContext;
