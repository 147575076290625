import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const Loading = (
  <div
    style={{
      height: "75vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Spin size="large" indicator={antIcon} tip="Loading..."/>
  </div>
);

export default Loading;
